.blue {
    background-color: #517eff;
    color: #fff;
}
.lightblue {
    background-color: #7dbee8;
}
.green {
    background-color: #7fd0cb;
}
.darkgreen {
    background-color: #72b1a2;
}
.yellow {
    background-color: #e0ad3a;
}
.orange {
    background-color: #df873c;
}
.red {
    background-color: #df603f;
}
.page {
    margin: 0 auto;
    padding: 0 1em;
    max-width: 1600px;    
}

.row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.tile {
    background: #fff;
    margin: 1em;
    padding: 1em;
    box-shadow:
        0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
}
.triangle {
    height: 16px;
    width: auto;
    margin-right: 5px;
    display: inline-block;
}
.tile h2, .tile h3 {
    margin-top: 0;
}
.title {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    
}
.title__img {
    width: 80px;
    height: auto;
    margin-right: 1em;
}
.filters {
    flex: 1;
}
.filters__input {
    flex: 1;
    border: 1px solid #ccc;
    margin: 0.5em;
    padding: 0 1em;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 60px;
}
.filters__input select {
    font-family: 'fugue', 'Roboto', sans-serif;
    border: none;
    font-size: 18px;
    margin-left: 2em;
    margin-bottom: -3px;
    flex-grow: 1;
}
.score {
    flex: 5;
    min-width: 400px;
    height: 400px;
}
.score__stats {
    text-align: center;
}
.score__stats h1 {
    font-size: 90px;
}
.score__stats p {
    font-size: 1.2em;
    margin: 0.5em 0;
}
.score__main {
    background-color: #7fd0cb;
    color: #fff;
    border-radius: 100%;
    height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3em auto;
}
.leaderboard {
    flex: 8;
    height: 400px;
}
.leaderboard__stat {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.table {
    overflow-y: auto;
    height: 330px;
}
.chart {
    flex: 1;
}
.chart__controls {
    display: flex;
}
.chart__control {
    margin-right: 1em;
    border-radius: 100%;
    height: 55px;
    width: 55px;
    border: none;
    background-color: #fff;
    cursor: pointer;
}
.chart__control img {
    width: 40px;
    height: 40px;
}
.chart__control.active {
    border: 2px solid #7fd0cb;

}
.chart__control:hover {
    background-color: #eee;
}
.chart__control:focus {
    background-color: #ddd;
}
.chart__control:last-child {
    margin-right: 0em;
}
.chart__info {
    visibility: hidden;
    margin-bottom: 2em;
}
.chart__info-value {
    margin: 0 1em 0.5em;
}
.chart__info-value b {
    display: inline-block;
    width: 75px;
}
svg {
    width: 100%;
}
svg#chart__formie {
    width: calc(100% + 1em);
    margin-bottom: -1em;
    margin-right: -1em;
}
table {
    margin: 0 auto 2em;
    width: 100%;
}
td.large {
    width: 50%;
}
tbody {
    overflow-y: scroll;
    height: 100%;
    
}
td {
    padding: 1em 2em;
}
.domain {
    stroke: #aaa;
}
.tick {
    font-family: 'fugue', 'Roboto', sans-serif;
    font-size: 11px;
}
.tick line {
    stroke: #888;
}
.reasons {
    justify-content: flex-start;
}
.reason {
    display: flex;
    width: 20%;
    min-width: 250px;
    padding: 1em;
    margin-bottom: 2em;
}
.reason__img {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 120px;
    min-height: 120px;
    padding-right: 1em;
}
.reason__detail {
    flex-grow: 1;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
}
.effect {
    height: 45px;
    width: 100%;
    margin: 0.5em 0;
}
.trend-path {
    fill: none;
    stroke: #888;
}
.trend-path-min {
    stroke: #df603f;
}
.trend-path-max {
    stroke: #517eff;
}
.text-min {
    fill: #df603f;
}
.text-max {
    fill: #517eff;
}
.detail-circle{
    stroke: #fff;
}
@media (prefers-color-scheme: dark) {
    .tile {
        background-color: #000e26;
    }
    text {
        fill: #fff;
    }
    .trend-path {
        stroke: #fff;
    }
    .detail-circle{
        stroke: #000e26;
    }
}