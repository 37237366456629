@font-face {
  font-family: 'Fugue Regular';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('./fonts/fugue_regular.ttf') format('truetype')
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0,0,0,.01) 0 0 1px;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

h1, h2, h3, h4, h5, h6, .font--title {
  font-family: 'Fugue Regular', sans-serif;
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea {
  font-family: 'Inter', sans-serif;
}

a {
  color: #387eff !important;
}

.yesNoHover:hover {
  opacity: 1 !important;
}

.opacityHover:hover {
  opacity: 0.8;
}

.unOpacityHover:hover {
  opacity: 1;
}

.centered {
  text-align: center;
}

.alwaysBlack {
  color: #162541;
}

input {
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
}

.active-img{
border: 1px solid grey;
}

table {
  border-collapse: collapse;
}

th {
  background-color: #f2f2f2;
  padding: 8px;
  text-align: left;
  border-color: #f2f2f2;
  border-width: 1px;
  border-style: solid;
}

td {
  padding: 8px;
  background-color: #fff;
  border-color: #f2f2f2;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
}

.buttonSpinner {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  animation: buttonSpinnerFrames 2s infinite;
}

@keyframes buttonSpinnerFrames {
  0% {
    opacity: 0.5;
    transform: scale3d(0.5, 0.5, 0.5);
  }
  50% {
    opacity: 0.1;
    transform: scale3d(5, 5, 5);
  }
  100% {
    opacity: 0.5;
    transform: scale3d(0.5, 0.5, 0.5);
  }
}


.overlay-appear {
  animation: overlayAppearFrames 0.5s;
}

@keyframes overlayAppearFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-appear {
  animation: modalAppearFrames 0.6s;
}

@keyframes modalAppearFrames {
  0% {
    opacity: 0;
  transform: translateY(100vh) scale3d(0.3,0.3,0.3)
  }
  20% {
    opacity: 0;
    transform: translateY(100vh) scale3d(0.3,0.3,0.3)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes toolTipAppearAnim {
  from{
      opacity: 0;
  }
  to{
      opacity: 1;
  }
}

.tooltip-appear {
  animation: toolTipAppearAnim 0.2s ease-in-out;
}

@keyframes rotating {
  from{
      -webkit-transform: rotate(0deg);
  }
  to{
      -webkit-transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 2s linear infinite;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #162541;
    color: #ffffff
  }

  th {
    background-color: #13376e;
    border-color: #13376e;
  }

  td {
    border-color: #13376e;
  }

  td {
    background: none;
  }
}

.DateRangePicker_picker td {
  padding: 0;
}

.DateInput_input {
  box-sizing: border-box;
}

.icon, .icon svg {
  display: block;
}

.bs, .bs *, .bs *:before, .bs *:after {
  box-sizing: border-box;
}

.w--auto {
  width: auto;
}

.icon--md {
  cursor: pointer;
  display: block;
  height: 25px;
  width: 25px;
}

.mr--10 {
  margin-right: 10px;
}

.link--clean {
  color: unset !important;
}

.link--white {
  color: white !important;
}

.display--block {
  display: block;
}